import { useState } from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import StatisticCard from "../components/dashboard/StatisticCard";
import View from "../components/View";
import { useAuthContext } from "../context/AuthContext";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../constants";

// import toast from "react-hot-toast";

import { getProxies } from "../api/proxies";
import { getTotalHashrate } from "../api/statistics";
import WorkersList, { ProxyTotals, WorkerMachinesDict } from "../components/dashboard/WorkersList";
import { TeamWebsocketContextProvider } from "../context/TeamWebsocketContext";
import { Proxy, Team, WebsocketUpdate, WorkerMachine } from "../types";


function Statistic({ proxyTotals } : { proxyTotals: ProxyTotals }) {
  // const [isHashrateLoading, setIsHashrateLoading] = useState<boolean>(true);
  // const [hashrate, setHashrate] = useState<number>(0);
  // const [prevHashrate, setPrevHashrate] = useState<number>(0);


  // const load = async () => {
  //   try {
  //     setIsHashrateLoading(true);
  //     const res = await getTotalHashrate(team.id);
  //     setHashrate(res.total_hashrate_ths);
  //     setPrevHashrate(res.prev_total_hashrate_ths);
  //   } catch (ex: any) {
  //     console.error("Error loading hashrate:", ex);
  //     toast.error(`Failed to fetch current hashrate: ${ex.message}`, { position: "bottom-right" });
  //   } finally {
  //     setIsHashrateLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (team) {
  //     load();
  //     const interval = setInterval(load, 3600000); // Update every hour
  //     return () => clearInterval(interval);
  //   }
  // }, [team]);

  return (<>
    <StatisticCard
      className="min-w-[320px] col-span-4"
      icon="gauge"
      title="Current Hashrate"
      description="Total hashrate across all proxies"
      value={(proxyTotals.totalHashrateOrder + proxyTotals.totalHashratePassthrough).toFixed(2)}
      suffix="TH/s"
      // loadingPlaceholder="..."
      // loading={isHashrateLoading}
    />
    <StatisticCard
      className="min-w-[320px] col-span-4 "
      icon="gauge"
      title="Orders Hashrate"
      description="Total hashrate servicing orders"
      value={proxyTotals.totalHashrateOrder.toFixed(2)}
      suffix="TH/s"
      // loadingPlaceholder="..."
      // loading={isHashrateLoading}
    />
    <StatisticCard
      className="min-w-[320px] col-span-4"
      icon="gauge"
      title="Pass-through Hashrate"
      description="Total hashrate in passthrough mode"
      value={proxyTotals.totalHashratePassthrough.toFixed(2)}
      suffix="TH/s"
      // loadingPlaceholder="..."
      // loading={isHashrateLoading}
    />
    </>
  );
}


export default function DashboardRoute() {

  const [workerMachines, setWorkerMachines] = useState<WorkerMachinesDict>({});
  const [proxyTotals, setProxyTotals] = useState<ProxyTotals>({
    totalHashrateOrder: 0,
    totalHashratePassthrough: 0
  });
  const { team }: { team: Team | undefined } = useAuthContext();

  const proxyUpdateCallback = (update: WebsocketUpdate) => {
    // console.log(update)

    switch (update.type) {
      case 'init': {
        const updateProxies: Proxy[] = update.payload
        const machines: WorkerMachinesDict = {}
        const totals: ProxyTotals = {
          totalHashratePassthrough: 0.0,
          totalHashrateOrder: 0.0,
        }

        updateProxies.filter(
          (p: Proxy) => p.team === team?.id 
        ).map((p: Proxy) => {
          const isPassThrough = (p.upstream?.pool_user.pool && team?.team_pool_users?.includes(
            `${p.upstream.pool_user.username}@${p.upstream.pool_user.pool}`
          )) as boolean
          p.workers.map((w: WorkerMachine) => {
            machines[w.id] = { 
              id: w.id,
              proxy: p, 
              worker: w,
              isPassThrough,
            }
            // aggregate totals
            if (isPassThrough) {
              // pass-through hashrate
              totals.totalHashratePassthrough += (w.hashrate?.average || 0.0) / 1e12
            } else {
              // order hashrate
              totals.totalHashrateOrder += (w.hashrate?.average || 0.0) / 1e12
            }
          })
        })
        // console.log(machines)
        setWorkerMachines(machines)
        setProxyTotals(totals)
        break
      }
      case 'worker_add': {
        break
      }
      case 'worker_remove': {
        break
      }
      case 'stats': {
        break
      }
      case 'worker_stats': {
        break
      }
      default: {
        break
      }
    }
  }

  return (
    <TeamWebsocketContextProvider 
      proxyUpdateCallback={proxyUpdateCallback}
    >
      <div className="flex flex-col">
        <Helmet>
          <title>{APP_NAME} - Dashboard</title>
        </Helmet>
        <Topbar />
        <div className="flex min-h-screen-excluding-topbar">
          <Sidebar />
          <View>
            <div className="p-3 w-full grid lg:h-full gap-4">
              <Statistic 
                proxyTotals={proxyTotals}
              />
              <WorkersList 
                className=" col-span-12 row-span-2 xl:row-span-3 "
                workerMachines={workerMachines}
              />
              
            </div>
          </View>
        </div>
      </div>
    </TeamWebsocketContextProvider>
  );
}
