export const AUTH_TOKEN = "rigly_token";
export const APP_NAME = "Rigly Portal";

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL ?? "";
export const BASE_PROXY_API_URL = process.env.REACT_APP_BASE_PROXY_API_URL ?? "";
export const BASE_PROXY_WS_URL = process.env.REACT_APP_BASE_PROXY_WS_URL ?? undefined;
export const BASE_PORTAL_URL = process.env.REACT_APP_BASE_PORTAL_URL ?? "";
export const SPOOF_EMAIL = process.env.REACT_APP_SPOOF_EMAIL ?? undefined;

export const RIGLY_TEAM_ID = "RIGLY_TEAM_ID";
