import { get, post, url } from "@rigly/core";
import toast from "react-hot-toast";
import { PayoutResolution } from "../types";
import { getToken } from "../utils/token";
import { getDefaultHeaders } from "../utils/headers";
import { BASE_API_URL } from "../constants";
import { DateRangeType } from "react-tailwindcss-datepicker";
import { formatISO } from "date-fns";

export async function requestPayout(epoch: number) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    const res = await post(
      url(BASE_API_URL, "/api/sellers/payouts/request"),
      {
        headers: getDefaultHeaders(token),
      },
      {
        epoch,
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}


export async function getPayoutsDaily(
  limit: number,
  offset: number,
  teamId: number,
  startDate?: string,
  endDate?: string,
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/payouts/daily?limit=${limit || 8}&offset=${offset || 0}`;
    if (startDate) {
      queryUrl += `&start-date=${startDate}`;
    }
    if (endDate) {
      queryUrl += `&end-date=${endDate}`;
    }

    const res = await post(
      url(BASE_API_URL, queryUrl),
      {
        headers: getDefaultHeaders(token),
      },
      {
        team: teamId,
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}


export async function getPayouts(
  limit: number,
  offset: number,
  resolution: PayoutResolution,
  teamId: number,
  epoch?: number,
  date?: any
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/payouts?resolution=${resolution}&limit=${limit || 8}&offset=${offset || 0}`;
    if (epoch !== undefined) {
      queryUrl += `&epoch=${epoch}`;
    }
    if (date !== undefined) {
      queryUrl += `&date=${date}`;
    }

    const res = await post(
      url(BASE_API_URL, queryUrl),
      {
        headers: getDefaultHeaders(token),
      },
      {
        team: teamId,
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}




export async function getPayoutsChart(
  resolution: PayoutResolution,
  teamId: number,
  epoch?: number,
  date?: any,
  dateRange?: DateRangeType,
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/payouts/chart?resolution=${resolution}`;
    if (epoch !== undefined) {
      queryUrl += `&epoch=${epoch}`;
    }
    if (date !== undefined) {
      queryUrl += `&date=${date}&type=worker`;
    }
    if (dateRange && resolution == 'range') {
      const today = formatISO(new Date(), { representation: 'date' })
      const start = dateRange.startDate ? formatISO(dateRange.startDate as Date, { representation: 'date' }) : today
      const end = dateRange.endDate ? formatISO(dateRange.endDate as Date, { representation: 'date' }) : today
      queryUrl += `&start=${start}&end=${end}`
    }

    const res = await post(
      url(BASE_API_URL, queryUrl),
      {
        headers: getDefaultHeaders(token),
      },
      {
        team: teamId,
      },
      
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}


export async function getPayoutsCSV(
  epoch?: number,
  teamId?: number,
  dateRange?: DateRangeType,
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/payouts/csv`;

    const queryParams = []
    if (epoch !== undefined) {
      queryParams.push(`epoch=${epoch}`);
    }
    if (teamId !== undefined) {
      queryParams.push(`team=${teamId}`);
    }
    if (dateRange?.startDate) {
      const start = formatISO(dateRange.startDate as Date, { representation: 'date' })
      queryParams.push(`start=${start}`)
    }
    if (dateRange?.endDate) {
      const end = formatISO(dateRange.endDate as Date, { representation: 'date' })
      queryParams.push(`end=${end}`)
    }

    if (queryParams.length > 0) {
      queryUrl = [queryUrl, queryParams.join('&')].join('?')
    }

    const res = await fetch(
      url(BASE_API_URL, queryUrl),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'text/html',
        },
      }
    )
    
    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}
