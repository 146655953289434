import { Button, Breadcrumbs, Card, List } from "@rigly/core";

import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useEffect, useState } from "react";
import { getPayoutsChart, getPayoutsCSV, getPayoutsDaily } from "../../api/payouts";
// import { format } from "date-fns";
import { useNavigate } from "react-router";
import Plot from "../../components/Plot";
// import clsx from "clsx";
import { PayoutDailyResponse } from "../../types";
import View from "../../components/View";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";
import Toolbar from "../../components/Toolbar";
import Pagination from "../../components/Pagination";
import exportFromJSON from "export-from-json";
import { listUTCDateFormat } from "../../lib/utc-date-fns";
import { UTCDate } from "@date-fns/utc";
import { formatISO } from "date-fns";

import DatePicker, { DateRangeType, DateValueType } from "react-tailwindcss-datepicker";
import { ArrowRightToLine, ChevronLeft, ChevronRight } from "lucide-react";
import clsx from "clsx";


const dateNavButtonStyle = `
  rounded-lg px-3 mx-1
  bg-blue-100 hover:bg-blue-400 aria-checked:bg-blue-400
  dark:bg-sky-900 dark:hover:bg-sky-800 dark:aria-checked:bg-sky-700
  aria-checked:pointer-events-none 
  aria-disabled:pointer-events-none aria-disabled:opacity-70
  text-sm select-none
  text-sky-600 hover:text-white aria-checked:text-white aria-disabled:text-sky-400
  dark:text-slate-200 aria-checked:dark:text-slate-50 aria-disabled:dark:text-slate-500
`

const downloadButtonStyle = `
  rounded-md 
  bg-blue-500 hover:bg-blue-500 
  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 
  disabled:opacity-50 disabled:cursor-not-allowed
  p-3 
  text-xs font-semibold shadow-sm 
  text-white 
`

export default function DailyPayouts() {
  const { team } = useAuthContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [payouts, setPayouts] = useState<PayoutDailyResponse[]>([]);
  const [chartData, setChartData] = useState<any>(undefined);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined
  );
  // const [totalActive, setTotalActive] = useState<boolean>(true);
  // const [averageActive, setAverageActive] = useState<boolean>(true);
  
  const totalActive = true
  const averageActive = true

  // pagination
  const [limit, setLimit] = useState<number>(8);
  const [activePage, setActivePage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  // date filtering
  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 89)),
    endDate: new Date(),
  })

  const rangeDays = (dateRange?.endDate && dateRange?.startDate) 
  ? Math.round(((dateRange.endDate as any) - (dateRange.startDate as any)) / (1000 * 60 * 60 * 24)) + 1
  : ( (chartData?.data[0]?.x && chartData.data[0].x.length > 0) ?
    Math.round(((new Date(chartData.data[0].x[0] as string) as any) - (new Date(chartData.data[0].x.slice(-1)[0] as string) as any)) / (1000 * 60 * 60 * 24)) + 1
    : 0
  )

  const setStartBySpan = (span: number) => {
    if (dateRange) {
      const endDate = (dateRange.endDate) ? new Date(dateRange.endDate) : new Date()
      const startDate = new Date(endDate)
      startDate.setDate(endDate.getDate() - span)
      setDateRange({ startDate, endDate })
    }    
  }

  const offsetDateRange = (offset?: number) => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      const maxOffset = Math.round(((new Date() as any) - (dateRange.endDate as any)) / (1000 * 60 * 60 * 24))
      
      if (offset === undefined || offset > maxOffset) {
        offset = maxOffset
      } 
      
      const startDate = new Date(dateRange.startDate)
      startDate.setDate(dateRange.startDate.getDate() + offset)

      const endDate = new Date(dateRange.endDate)
      endDate.setDate(dateRange.endDate.getDate() + offset)
      
      setDateRange({ startDate, endDate })
    }    
  }
    
  const hanldeExportCSV = async () => {
    const res = await getPayoutsCSV(
      undefined,
      team?.id,
      dateRange ? dateRange as DateRangeType : undefined,
    )
    
    if (res) {
      const text = await res.text()

      if (text) {
        exportFromJSON({ 
          data: text, 
          fileName: 'revenue',
          extension: 'csv',
          exportType: exportFromJSON.types.txt, 
        })
      }
    }
  
  }

  const loadPayouts = async () => {
    try {
      setLoading(true);

      const res = await getPayoutsDaily(
        limit, 
        activePage * limit, 
        team?.id,
        dateRange?.startDate ? formatISO(dateRange.startDate as Date, { representation: 'date' }) : undefined,
        dateRange?.endDate ? formatISO(dateRange.endDate as Date, { representation: 'date' }) : undefined,
      );
      if (res && res.results) {
        setPayouts(res.results);
        setCount(res.count);
      }
    } catch (ex: any) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  // const sameDay = (d1: Date, d2: Date) => ( 
  //   d1.getDate() === d2.getDate() && 
  //   d1.getMonth() === d2.getMonth() &&
  //   d1.getFullYear() === d2.getFullYear()
  // )

  const loadChart = async () => {
    try {
      setLoadingChart(true);
      const useRange = dateRange?.startDate || dateRange?.endDate
      const res = await getPayoutsChart(
        useRange ? 'range' : 'all', 
        team?.id,
        undefined, undefined,
        useRange ? dateRange : undefined
      );
      setChartData(res)
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-right" });
    } finally {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    if (team) {
      loadPayouts()
      loadChart()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team])

  useEffect(() => {
    if (team) {
      setSelectedDate(undefined)
      loadChart()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  useEffect(() => {
    if (team) {
      loadPayouts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, activePage, dateRange])

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Revenue</title>
      </Helmet>
      <Topbar />
      <div className="flex min-h-screen-excluding-topbar">
        <View className="bg-radial-gradient-dark dark:bg-radial-gradient-light">
        <Sidebar />
          <div className="p-1">
            <Card className="pt-3 p-4 gap-4 items-start h-full payouts-grid bg-radial-gradient-dark dark:bg-radial-gradient-light">
              <Breadcrumbs
                items={[{ label: "All", href: "/revenue" }]}
                onNavigate={(to: string) => navigate(to)}
              />
              <Toolbar className='!items-center !flex-row justify-between overflow-visible'>
                <div className="flex flex-grow flex-row space-x-1">
                  
                  <button 
                    className={clsx(dateNavButtonStyle)}
                    aria-disabled={ !dateRange?.startDate }
                    onClick={() => { offsetDateRange(-rangeDays) }}
                  ><ChevronLeft size={20} /></button>

                  <div className="flex space-x-2 items-center text-zinc-400 ">
                    <span className="text-nowrap text-sm w-[80px] text-center">
                      {rangeDays} {rangeDays == 1 ? 'day' : 'days'}
                    </span>
                    <DatePicker
                      primaryColor={"blue"}
                      // popupClassName={'bg-zinc-200 dark:bg-zinc-800'}
                      value={dateRange} 
                      onChange={(value: DateValueType) => { setDateRange(value) }} 
                    />
                  </div>
                  
                  <button 
                    className={clsx(dateNavButtonStyle)}
                    aria-disabled={!dateRange?.endDate || Math.round(((new Date() as any) - (dateRange.endDate as any)) / (1000 * 60 * 60 * 24)) === 0}
                    onClick={() => { offsetDateRange(rangeDays) }}
                  ><ChevronRight size={20} /></button>

                  <button 
                    className={clsx(dateNavButtonStyle)}
                    aria-disabled={!dateRange?.endDate || Math.round(((new Date() as any) - (dateRange.endDate as any)) / (1000 * 60 * 60 * 24)) === 0}
                    onClick={() => { offsetDateRange() }}
                  ><ArrowRightToLine size={20} /></button>
                  
                  <span className="px-1"></span>

                  <button 
                    className={clsx(dateNavButtonStyle)}
                    aria-checked={rangeDays === 90}
                    onClick={() => { setStartBySpan(89) }}
                  >90</button>
                  
                  <button 
                    className={clsx(dateNavButtonStyle)}
                    aria-checked={rangeDays === 30}
                    onClick={() => { setStartBySpan(29) }}
                  >mo</button>
                  
                  <button 
                    className={clsx(dateNavButtonStyle)}
                    aria-checked={rangeDays === 14}
                    onClick={() => { setStartBySpan(13) }}
                  >2w</button>
                  
                  <button 
                    className={clsx(dateNavButtonStyle)}
                    aria-checked={rangeDays === 7}
                    onClick={() => { setStartBySpan(6) }}
                  >1w</button>

                </div>
                <div className="flex flex-row space-x-1">
                  <button
                    type="button"
                    onClick={hanldeExportCSV}
                    disabled={loading || Boolean(!payouts?.length)}
                    className={clsx(downloadButtonStyle)}
                  >
                    Download csv
                  </button>
                </div>
              </Toolbar>
              <List
                loading={loading}
                loadingMessage="Crunching data..."
                data={payouts}
                className="w-full h-full min-h-[40vh]"
                spanClass="text-white text-xs"
                onClick={(e: any, payout: PayoutDailyResponse) => {
                  if (e.detail === 1) {
                    setSelectedDate(payout.date);
                  }
                  if (e.detail === 2) {
                    if (!payout.date) { return }
                    navigate(`/revenue/${formatISO(
                      new UTCDate(payout.date),
                      {representation: 'date'}
                    )}`)
                  }
                }}
                selected={(payout: PayoutDailyResponse) =>
                  payout.date === selectedDate
                }
                schema={[
                  {
                    label: "Date",
                    key: (payout: PayoutDailyResponse) => {
                      const formattedDate = payout.date
                        ? listUTCDateFormat(new UTCDate(payout.date))
                        : "Unknown";

                        return (
                        <div className="flex items-center justify-start gap-1">
                          <span className="text-zinc-600 dark:text-white text-xs">
                            {formattedDate}
                          </span>
                        </div>
                      );
                    },
                    type: "custom",
                  },
                  { label: "Epoch", key: "epoch", type: "number", align: "left" },
                  {
                    label: "Total Hashrate [TH/s]",
                    type: "custom",
                    key: (payout: PayoutDailyResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.average_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "Passthrough [TH/s]",
                    type: "custom",
                    key: (payout: PayoutDailyResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.seller_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "Rigly Premium [TH/s]",
                    type: "custom",
                    key: (payout: PayoutDailyResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.order_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "FPPS Hashprice",
                    type: "sats",
                    key: (payout: PayoutDailyResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.hashprice_satsthsd.toFixed(3)}
                      </span>
                    ),
                  },
                  {
                    label: `Rigly Hashprice (+${team?.hash_price_premium}%)`,
                    type: "sats",
                    key: (payout: PayoutDailyResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.premium_hashprice_satsthsd.toFixed(3)}
                      </span>
                    ),
                  },
                  {
                    label: "Balance",
                    type: "custom",
                    key: (payout: PayoutDailyResponse) => {
                      return (
                        <span className="text-zinc-600 dark:text-white text-xs">
                          {(payout.payout_amount_sats / 10 ** 8).toFixed(8)} BTC
                        </span>
                      );
                    },
                  },
                  /*
                  {
                    label: "TX ID",
                    type: "custom",
                    key: (payoutRes: PayoutDailyResponse) => {
                      if (!payoutRes.payout || !payoutRes.payout.txid) {
                        return (
                          <div className="w-full h-full flex justify-center items-center">
                            <span className="text-zinc-600 dark:text-white text-xs">
                              -
                            </span>
                          </div>
                        );
                      }
                      return (
                        <div className="w-full h-full flex justify-center items-center">
                          <button
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (!payoutRes.payout) {
                                return;
                              }
                              window.open(
                                `https://mempool.space/address/${payoutRes.payout.txid}`,
                                'target="_blank"'
                              );
                            }}
                            className="text-xs text-white underline"
                          >
                            {formatTxId(payoutRes.payout.txid)}
                          </button>
                        </div>
                      );
                    },
                  },
                  {
                    label: "Actions / Status",
                    type: "custom",
                    key: (payoutRes: PayoutDailyResponse) => {
                      return <RequestPayoutButton payoutRes={payoutRes} />;
                    },
                  },
                  */
                ]}
              />

              <Toolbar className='!items-center !flex-row justify-between'>
                <Pagination
                  count={count}
                  limit={limit}
                  setLimit={setLimit}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              </Toolbar>

              <div className="w-full h-full rounded-lg bg-zinc-100 dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-700 flex flex-col relative">
                <div className="w-full pl-3 pr-1 py-1 border-b border-zinc-300 dark:border-zinc-700 flex items-center justify-between">
                  <span className="text-xs text-zinc-600">
                    Showing -{" "}
                    <b>{selectedDate ? `Date ${selectedDate}` : "All"}</b>
                  </span>
                  {/* <div className="flex items-center justify-center gap-4 absolute w-full h-full pointer-events-none">
                    {[
                      {
                        label: "Total",
                        active: totalActive,
                        toggle: () => setTotalActive(!totalActive),
                      },
                      {
                        label: "Average",
                        active: averageActive,
                        toggle: () => setAverageActive(!averageActive),
                      },
                    ].map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          className="flex justify-start gap-2 items-center pointer-events-auto cursor-pointer"
                          onClick={item.toggle}
                        >
                          <div
                            className={clsx("w-3 h-3 rounded-sm border", {
                              "border-white/50": item.active,
                              "border-white/20": !item.active,
                            })}
                            style={{
                              background: getColorByIndex(
                                i,
                                item.active ? 1 : 0.5
                              ),
                            }}
                          />
                          <span
                            className={clsx("text-xs font-bold", {
                              "text-zinc-500 dark:text-white": item.active,
                              "text-zinc-300 dark:text-white/50": !item.active,
                            })}
                          >
                            {item.label}
                          </span>
                        </div>
                      );
                    })}
                  </div> */}
                  {selectedDate && (
                    <Button
                      className="w-auto px-2 h-[32px] text-xs"
                      onClick={() => setSelectedDate(undefined)}
                    >
                      Show All
                    </Button>
                  )}
                  {!selectedDate && <div className="h-[32px]" />}
                </div>
                <Plot
                  className="w-full h-full"
                  data={chartData?.data.map((dataset: any) => {
                    if (
                      (!averageActive &&
                        dataset.meta.key === "average_hashrate") ||
                      (!totalActive && dataset.meta.key === "total_hashrate")
                    ) {
                      return {
                        ...dataset,
                        x: [],
                        y: [],
                      };
                    }

                    if (!selectedDate || dataset.type === 'bar') {
                      return dataset;
                    }

                    const dates: any = [];
                    const hashrates: any = [];
                    dataset.x.forEach((ts: string, i: number) => {
                      if (ts.slice(0,10) == selectedDate) {
                        dates.push(ts);
                        hashrates.push(dataset.y[i]);
                      }
                    });

                    return {
                      ...dataset,
                      x: dates,
                      y: hashrates,
                    };
                  })}
                  loading={loadingChart}
                  loadingMessage="Crunching data..."
                  xaxis={chartData?.layout?.xaxis}
                  yaxis={chartData?.layout?.yaxis}
                  layout={chartData?.layout}
                />
              </div>
            </Card>
          </div>
          </View>
      </div>
    </div>
  );
}
