import joi from "joi";
import { useEffect, useState } from "react";
import { Card, Loader } from "@rigly/core";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useAuthContext } from "../context/AuthContext";
import { updateTeam } from "../api/team";
import toast from "react-hot-toast";
// import View from "../components/View";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../constants";
import { AuthContextType, Team } from "../types";

const styleInputLabel = `text-sm text-zinc-700 dark:text-white font-semibold mb-[2px]`
const styleInputDesc = `text-xs text-zinc-500 dark:text-white/50 mb-2`
const styleInputField = `
  text-sm 
  text-zinc-800 dark:text-white 
  bg-zinc-100 dark:bg-zinc-900 
  mb-[2px] mr-auto w-full h-10 px-3 border 
  rounded-lg outline-none 
  dark:placeholder-zinc-500 border-zinc-200 dark:border-zinc-700 focus:border-zinc-400 dark:focus:border-zinc-500`
const styleInputDropdown = `
  text-sm text-zinc-500 dark:text-white 
  mb-[2px] capitalize mr-auto w-64 h-10 px-3 
  border bg-zinc-100 dark:bg-zinc-900 rounded-lg outline-none 
  dark:placeholder-zinc-500 
  border-zinc-200 dark:border-zinc-700 focus:border-zinc-400 dark:focus:border-zinc-500`

const styleInputLabelLocked = `text-sm text-zinc-700 dark:text-white font-semibold mb-[2px]`
const styleInputDescLocked = `text-xs text-zinc-500 dark:text-white/50 mb-2`
const styleInputFieldLocked = `
  text-sm flex items-center
  dark:text-white text-zinc-700
  mb-[2px] mr-auto w-full h-10 px-3 
  bg-zinc-200 bg-opacity-30 dark:bg-zinc-800 dark:bg-opacity-30
  rounded-lg outline-none`
const styleInputDropdownLocked = `
  text-sm dark:text-white text-zinc-700
  mb-[2px] capitalize mr-auto w-64 h-10 px-3 
  bg-zinc-200 bg-opacity-30 dark:bg-zinc-800 dark:bg-opacity-30
  rounded-lg outline-none border-zinc-200 dark:border-zinc-700 flex items-center`


type SellerSettingsType = {
  payout_address?: string;
  payout_frequency?: string;
  payout_day?: number;
  legal_address?: string;
}

const sellerSettingsSchema = joi.object({
  payout_address: joi
    .string().error(new Error("Payout address must be a string"))
    .required().error(new Error("Payout address is required")),
  payout_frequency: joi
    .string().error(new Error("Invalid Payout frequency"))
    .required().error(new Error("Payout frequency is required"))
    .valid(
      'daily',
      'weekly',
      'biweekly',
      'monthly',
    ).error(new Error("Invalid Payout frequency")),
  legal_address: joi
    .string().allow('')
    .optional(),
  payout_day: joi
    .number()
    .min(0).max(6)
    .optional(),
})


export default function SettingsRoute() {
  const { loading, team, setTeam } : AuthContextType = useAuthContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // const [values, setValues] = useState(undefined);

  const [locked, setLocked] = useState<boolean>(true)
  const [settings, setSettings] = useState<SellerSettingsType>({})
  const [savedSettings, setSavedSettings] = useState<SellerSettingsType>({})

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const { error, value } = sellerSettingsSchema.validate(settings)
      console.log({ error, value })

      if (!error) {
        await updateTeam(team.id, settings)
        toast.success("Account updated", { position: "bottom-right" })
        // const teamsRes = await auth.getTeams()
        // setTeams(teamsRes)

        setSavedSettings({ ...settings })
        setTeam({...team, ...settings})
        setLocked(true)
      } else {
        toast.error(error.message)
      }
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setIsSubmitting(false)
    }
  };

  useEffect(() => {
    if (team) {
      const teamSettings = {
        payout_address: team.payout_address,
        payout_frequency: team.payout_frequency,
        payout_day: team.payout_day,
        legal_address: team.legal_address,
      }
      setSavedSettings({ ...teamSettings })
      setSettings({ ...teamSettings })
    }
  }, [team]);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Settings</title>
      </Helmet>
      <Topbar />
      <div className="flex flex-row h-screen-excluding-topbar">
        <Sidebar />
        <div className="w-full">
          <Card className="p-4 bg-radial-gradient-dark dark:bg-radial-gradient-light m-2 max-w-screen-sm">
            {loading && (
              <div className="flex items-center justify-center">
                <Loader className="text-white w-8 h-8" />
              </div>
            )}

            {!loading && !settings && (
              <div className="flex items-center justify-center py-10">
                <span className="text-sm text-white">
                  Error: Could not load team
                </span>
              </div>
            )}

            {!loading && settings && (<>
              {/* <Form
                schema={[
                  {
                    type: "text",
                    id: "payout_address",
                    description: "Your BTC address for payouts",
                    label: "Payout address",
                    placeholder: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    required: true,
                    schema: joi
                      .string()
                      .required()
                      .error(new Error("Payout address is required")),
                  },
                  { type: "submit", id: "submit", label: "Save" },
                ]}
                values={values}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
              <br/>
              <Form
                schema={[
                  {
                    type: "text",
                    id: "legal_address",
                    description: "Legal address for invoices and receipts",
                    label: "Invoice address",
                    placeholder: "",
                    required: false,
                    schema: joi
                      .string()
                      .allow(''),
                  },
                  { type: "submit", id: "submit", label: "Save" },
                ]}
                values={values}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              /> */}
              <br/>
                {locked ? <>
                  {/* 
                  LOCKED 
                  */}
                  <div className="flex flex-col w-full">
                    <label htmlFor="payout_address" className={`${styleInputLabelLocked}`}>Payout address</label>
                    <span className={`${styleInputDescLocked}`}>Your BTC address for payouts</span>
                    <span className={`${styleInputFieldLocked}`}>
                      { settings.payout_address }
                    </span>
                  </div>
                  <br/>
                  <div className="flex flex-col w-full">
                    <label htmlFor="legal_address" className={`${styleInputLabelLocked}`}>Invoice address</label>
                    <span className={`${styleInputDescLocked}`}>Legal address for invoices and receipts</span>
                    <span className={`${styleInputFieldLocked}`}>
                      { settings.legal_address }
                    </span>
                  </div>
                  <br/>
                  <div className="flex flex-col w-full">
                    <label htmlFor="payout_frequency" className={`${styleInputLabelLocked}`}>Payout frequency</label>
                    <span className={`${styleInputDescLocked}`}>Prefered payout frequency</span>
                    <span className={`${styleInputDropdownLocked}`}>
                      { settings.payout_frequency }
                    </span>
                  </div>
                  <br/>
                  <div className="flex flex-col w-full">
                    <label htmlFor="payout_day" className={`${styleInputLabelLocked}`}>Payout day</label>
                    <span className={`${styleInputDescLocked}`}>Prefered payout day of the week</span>
                    <span className={`${styleInputDropdownLocked}`}>
                      { settings.payout_day !== undefined ? [
                          'Monday',
                          'Tuesday',
                          'Wednesday',
                          'Thursday',
                          'Friday',
                          'Saturday',
                          'Sunday',
                        
                      ][settings.payout_day] : '' }
                    </span>
                  </div>
                  <br/>
                  <div
                    className="flex flex-row gap-2"
                  >
                    <button
                      onClick={() => {
                        setSettings({ ...savedSettings })
                        setLocked(false)}
                      }
                      className="px-3 transition-all duration-200 text-sm font-sans font-bold text-zinc-600 dark:text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-300 border border-zinc-300 dark:border-zinc-600 h-10 rounded-md cursor-pointer hover:border-zinc-400 hover:bg-zinc-200 dark:hover:bg-zinc-700 flex items-center justify-start gap-3 bg-transparent dark:bg-transparent"
                    >
                      Unlock
                    </button>
                  </div>
                </> : <>
                  {/* 
                  UNLOCKED 
                  */}
                  <form
                    onSubmit={ handleSubmit }
                  > 
                    <div className="flex flex-col w-full">
                      <label htmlFor="payout_address" className={`${styleInputLabel}`}>Payout address</label>
                      <span className={`${styleInputDesc}`}>Your BTC address for payouts</span>
                      <input
                        className={`${styleInputField}`}
                        defaultValue={ settings.payout_address }
                        onChange={ (e) => {setSettings({ ...settings, payout_address: e.target.value})} }
                      />
                    </div>
                    <br/>
                    <div className="flex flex-col w-full">
                      <label htmlFor="legal_address" className={`${styleInputLabel}`}>Invoice address</label>
                      <span className={`${styleInputDesc}`}>Legal address for invoices and receipts</span>
                      <input 
                        className={`${styleInputField}`}
                        defaultValue={ settings.legal_address }
                        onChange={ (e) => {setSettings({ ...settings, legal_address: e.target.value})} }
                      />
                    </div>
                    <br/>
                    <div className="flex flex-col w-full">
                      <label htmlFor="payout_frequency" className={`${styleInputLabel}`}>Payout frequency</label>
                      <span className={`${styleInputDesc}`}>Prefered payout frequency</span>
                      <select
                        id="payout_frequency" 
                        name="payout_frequency" 
                        className={`${styleInputDropdown}`}
                        defaultValue={settings.payout_frequency}
                        onChange={(e) => {setSettings({ ...settings, payout_frequency: e.target.value })}}
                      >
                        <option value={'daily'}>Daily</option>
                        <option value={'weekly'}>Weekly</option>
                        <option value={'biweekly'}>Biweekly</option>
                        <option value={'monthly'}>Monthly</option>
                      </select>
                    </div>
                    {(settings.payout_frequency !== 'daily') ? <><br/>
                    <div className="flex flex-col w-full">
                      <label htmlFor="payout_day" className={`${styleInputLabel}`}>Payout day</label>
                      <span className={`${styleInputDesc}`}>Prefered payout day of the week</span>
                      <select
                        id="payout_day"
                        name="payout_day"
                        className={`${styleInputDropdown}`}
                        defaultValue={settings.payout_day}
                        onChange={(e) => {setSettings({ ...settings, payout_day: parseInt(e.target.value) })}}
                        >        
                        <option key={0} value={0}>Monday</option>
                        <option key={1} value={1}>Tuesday</option>
                        <option key={2} value={2}>Wednesday</option>
                        <option key={3} value={3}>Thursday</option>
                        <option key={4} value={4}>Friday</option>
                        <option key={5} value={5}>Saturday</option>
                        <option key={6} value={6}>Sunday</option>
                      </select>
                    </div></> : <></> }
                    <br/>
                    <div
                      className="flex flex-row gap-2"
                    >
                      <button
                        onClick={() => {
                          setSettings({ ...savedSettings })
                          setLocked(true)
                        }}
                        className="px-3 transition-all duration-200 font-sans font-bold text-zinc-600 dark:text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-300 border border-zinc-300 dark:border-zinc-600 h-10 rounded-md cursor-pointer hover:border-zinc-400 hover:bg-zinc-200 dark:hover:bg-zinc-700 text-sm flex items-center justify-start gap-3 bg-transparent dark:bg-transparent"
                        >
                        Cancel
                      </button>
                      <button
                        type="submit" id="submit"
                        onClick={ handleSubmit }
                        className="px-3 transition-all duration-200 font-sans font-bold text-zinc-600 dark:text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-300 border border-zinc-300 dark:border-zinc-600 h-10 rounded-md cursor-pointer hover:border-zinc-400 hover:bg-zinc-200 dark:hover:bg-zinc-700 text-sm flex items-center justify-start gap-3 bg-transparent dark:bg-transparent"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </>}
            </>)}
          </Card>
        </div>
      </div>
    </div>
  );
}
