import { useQuery } from 'react-query';
import { getTransactions } from '../api/transactions';

export const useGetTransactions = ({
  activePage,
  limit,
  teamId,
}: {
  activePage: number;
  limit: number;
  teamId: number;
}) => {
  return useQuery(
    ['transactions', activePage, teamId],
    async () => {
      const data = await getTransactions({
        teamId: teamId,
        limit,
        offset: activePage * limit,
      });
      return data;
    },
    { keepPreviousData: true, enabled: !!teamId }
  );
};
