import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useAuthContext } from "./AuthContext";
import { Team, WebsocketUpdate } from "../types";
import { BASE_PROXY_WS_URL } from "../constants";
// import { testInitPayload } from "./test_data";


export type TeamWebsocketContext = {
  isConnected: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  subscribe: (channel: string) => Promise<void>;
  unsubscribe: (channel: string) => void;
};

export const TeamWebsocketContext = createContext<TeamWebsocketContext>({
  isConnected: false,
  connect: async () => {},
  disconnect: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subscribe: async (channel: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  unsubscribe: (channel: string) => {},
});

// eslint-disable-next-line react-refresh/only-export-components
export const useTeamWebsocketContext = () => useContext(TeamWebsocketContext)

enum wsStateEnum {
  'closed',
  'opening',
  'open',
  'subscribing',
  'subscribed',
  'resubscribing',
  'closing',
}

let ws: WebSocket;

const channels: { [key: string]: any[] } = {}

let statCounter = 0
let wsState = wsStateEnum.closed


export function TeamWebsocketContextProvider({
  proxyUpdateCallback,
  children,
}: {
  proxyUpdateCallback?: (update: WebsocketUpdate) => void,
  children?: ReactNode;
}) {
  const { team, token }: { 
    team: Team | undefined;
    token: string | undefined | null;
} = useAuthContext();

  const [isConnected, setIsConnected] = useState<boolean>(false)


  const connect = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!BASE_PROXY_WS_URL) {
        throw new Error('WebSocket URL not configured')
      }

      if (wsState !== wsStateEnum.opening) {
        reject('wrong WS state for openning new connection')
        return
      }
  
      ws = new WebSocket(BASE_PROXY_WS_URL, ['Bearer', token ?? 'error']);

      ws.onopen = () => {
        setIsConnected(true)
        wsState = wsStateEnum.open
        console.log("[ws] connected to ", BASE_PROXY_WS_URL, wsState)
     
        if (proxyUpdateCallback) {
          // auto sub to proxies
          wsState = wsStateEnum.subscribing
          subscribe("proxies")
        }

        resolve()
      }

      ws.onmessage = (e: any) => {
        const action = JSON.parse(e.data);
        switch (action.type) {
          case "update": {
            if (!(action.channel in channels)) {
              return;
            }
            const subscribers = channels[action.channel];
            subscribers.forEach((callback: any) => {
              callback(action.payload);
              // callback(testInitPayload);
            });

            if (action.payload.type === 'stats') {
              statCounter += 1
              if (statCounter > 50 && wsState === wsStateEnum.subscribed) {
                // resubscribe
                wsState = wsStateEnum.resubscribing
                statCounter = 0
                unsubscribe(action.channel)
              }
            }

            break;
          }
          case 'subscribe_ack': {
            // subscribe confirmation
            const channel: string = action.payload

            switch (wsState) {
              case wsStateEnum.closing:
                // disconnect after unsubscribe confirmed
                disconnect()
                break;
              default:
                if (!(channel in channels)) {
                  channels[channel] = [];
                }
                channels[channel].push(proxyUpdateCallback);
        
                wsState = wsStateEnum.subscribed
            }

            break;
          }
          case 'unsubscribe_ack':{
            // unsubscribe confirmation
            const channel: string = action.payload

            switch (wsState) {
              case wsStateEnum.closing:
                // disconnect after unsubscribe confirmed
                disconnect()
                break;
              case wsStateEnum.resubscribing:
                // if resub flag is set, reset the flag and resubscribe
                wsState = wsStateEnum.subscribing
                subscribe(channel)
                break;

            }
            break;
          }
          default: {
            console.log("[ws] received unknown action:", action, wsState);
            break;
          }
        }
      };

      ws.onerror = (err) => {
        reject(err);
      };
    });
  };

  const disconnect = async (): Promise<void> => {
    setIsConnected(false);
    ws.close();
    wsState = wsStateEnum.closed
    console.log("[ws] disconnected", wsState);
  };

  const send = (payload: any) => {
    if (ws?.readyState !== WebSocket.OPEN) {
      return;
    }

    ws.send(JSON.stringify(payload));
  };

  const subscribe = async (channel: string) => {
    send({ type: "subscribe", payload: channel });
    console.log("[ws] subscribe", channel, wsState);
  };

  const unsubscribe = (channel: string) => {
    if (!(channel in channels)) {
      return;
    }

    const idx = channels[channel].findIndex((cb) => cb === proxyUpdateCallback);
    channels[channel].splice(idx, 1);

    send({ type: "unsubscribe", payload: channel });
    console.log("[ws] unsubscribe", channel, wsState);
  };


  useEffect(() => {
    if (token && team && wsState === wsStateEnum.closed) {
      wsState = wsStateEnum.opening
      connect()
    }
    return () => {
      if (wsState !== wsStateEnum.closed) {
        wsState = wsStateEnum.closing
        if (proxyUpdateCallback) {
          unsubscribe("proxies")
        } else {
          disconnect() 
        }
      }
    }
  }, [token]);

  return (
    <TeamWebsocketContext.Provider
      value={{ isConnected, connect, disconnect, subscribe, unsubscribe }}
    >
      {children}
    </TeamWebsocketContext.Provider>
  );
}
