import clsx from "clsx";
import { List } from "@rigly/core";

// import { useAuthContext } from "../context/AuthContext";
// import { getMonthlyRevenue } from "../api/statistics";
import { Proxy, WorkerMachine } from "../../types";

export type WorkerMachineLine = {
  id: string;
  worker: WorkerMachine;
  proxy: Proxy;
  isPassThrough: boolean;
}

export type ProxyData = {
  workerMachineName: string;
  inboundAddress: string;
  outboundAddress: string;
  hashratePassthrough: number;
  hashratePassthrough_300s: number;
  hashratePassthrough_24h: number;
  hashrateOrder: number;
  hashrateOrder_300s: number;
  hashrateOrder_24h: number;
  acceptedShares: number;
  acceptedShares_24h: number;
  rejectedShares: number;
  rejectedShares_24h: number;
};

export type ProxyTotals = {
  totalHashratePassthrough: number;
  totalHashrateOrder: number;
};

export type WorkerMachinesDict = { 
  [id: string]: WorkerMachineLine; 
}

export default function WorkersList(
  { className, workerMachines }: 
  { 
    className?: string;
    workerMachines?: WorkerMachinesDict;
  }
) {
  // const { team } = useAuthContext();

  // const [revenue, setRevenue] = useState<any>(undefined);

  // const load = async () => {
  //   const res = getMonthlyRevenue(team.id);
  //   setRevenue(res);
  // };

  // useEffect(() => {
  //   if (team) {
  //     load();
  //   }
  // }, [team]);

  const machines: WorkerMachineLine[] = workerMachines ? Object.values(workerMachines) : []

  if (machines) {
    machines.sort((a: WorkerMachineLine, b: WorkerMachineLine)=> {
      if (a.worker.host === b.worker.host) {
        if (a.worker.port === b.worker.port) return 0
        return a.worker.port > b.worker.port ? 1 : -1
      }
      return a.worker.host > b.worker.host ? 1 : -1
    })
  }

  return (<>
    <div className={className}>
      <List 
        data={machines}
        
        className={clsx(`w-full h-full min-h-[40vh]`)}
        spanClass="text-white text-xs"
        schema={[
          {
            label: 'Worker Machine Name',
            type: 'custom',
            key: (line: WorkerMachineLine) => (
              <div className="text-zinc-600 dark:text-white text-xs">
                {line.worker.name}
              </div>
            )
          },
          {
            label: 'Machine Address',
            type: 'custom',
            key: (line: WorkerMachineLine) => (
              <div className="text-zinc-600 dark:text-white text-xs text-center font-mono">
                {`${line.worker.host}:${line.worker.port}`}
              </div>
            )
          },
          {
            label: 'Inbound Address',
            type: 'custom',
            key: (line: WorkerMachineLine) => (
              <div className="text-zinc-600 dark:text-white text-xs text-center font-mono">
                {`${line.proxy.host}:${line.proxy.port}`}
              </div>
            )
          },
          {
            label: 'Outbound Address',
            type: 'custom',
            key: (line: WorkerMachineLine) => (
              <div className="text-zinc-600 dark:text-white text-xs text-center font-mono">
                {
                  (line.isPassThrough) 
                  ? `${line.proxy.upstream?.pool_user.username}@${line.proxy.upstream?.pool_user.pool}` 
                  : 'Buyer'
                }
              </div>
            )
          },
          {
            label: 'Hashrate [TH/s]',
            type: 'custom',
            key: (line: WorkerMachineLine) => (
              <div className="text-zinc-600 dark:text-white text-xs font-mono text-right">
                {((line.worker.hashrate?.average || 0) / 1e12 )?.toFixed(2)}
              </div>
            )
          },
        ]}
      />
    </div>
  </>);
}
