import { useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { Card, List, Loader } from "@rigly/core";
import { Helmet } from "react-helmet";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { APP_NAME } from "../constants";
// import { format } from "date-fns";
import { Transaction } from "../types";
// import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import { useGetTransactions } from "../hooks/useGetTransactions";
import Toolbar from "../components/Toolbar";
import exportFromJSON from 'export-from-json'

import { getTransactionReceipt, getTransactionReceiptPDF, getTransactionsCSV } from "../api/transactions";
import { listUTCDateFormat } from "../lib/utc-date-fns";
import { UTCDate } from "@date-fns/utc";


export default function Transactions() {
  const { team, loading } = useAuthContext();
  // const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(0);

  const { data, isLoading } = useGetTransactions({
    teamId: team?.id,
    activePage,
    limit,
  });

  const downloadTransactionsCSV = async () => {
    const res = await getTransactionsCSV({
      teamId: team?.id,
      offset: activePage * limit,
      limit,
    })
    
    if (res) {
      const transactions = await res.text()

      if (transactions) {
        exportFromJSON({
          data: transactions,
          fileName: 'transactions',
          exportType: exportFromJSON.types.txt,
        })
      }
    }
  }

  const downloadReceiptText = async (tx: Transaction) => {
    const res = await getTransactionReceipt(tx.id)
    if (res) {
      const receipt = await res.text()

      if (receipt) {
        exportFromJSON({
          data: receipt,
          fileName: tx.filename,
          exportType: exportFromJSON.types.txt,
        })
      }
    }
  }

  const downloadReceiptPDF = async (tx: Transaction) => {
    const linkElem = document.createElement("a")
    document.body.appendChild(linkElem)
    linkElem.setAttribute('style', 'visibility:hidden')

    if (linkElem) {
      const res = await getTransactionReceiptPDF(tx.id)

      if (res) {
        const blob = await res.blob()
        const href = window.URL.createObjectURL(blob)
        
        linkElem.download = `${tx.filename}.pdf`
        linkElem.href = href
        linkElem.target = '_blank'

        linkElem.dispatchEvent(
          new MouseEvent(
            'click', {
              bubbles: false,
              cancelable: false,
              view: window,
            }
          )
        )
        window.URL.revokeObjectURL(href)
      }
      document.body.removeChild(linkElem)
    }
  }

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Transactions</title>
      </Helmet>
      <Topbar />
      <div className="flex min-h-screen-excluding-topbar">
        <View className="bg-radial-gradient-dark dark:bg-radial-gradient-light">
          <Sidebar />
          <div className="p-3">
            <Card className="pt-2 p-4 items-start bg-radial-gradient-dark dark:bg-radial-gradient-light before:relative space-y-3">
              <Toolbar className='!items-center !flex-row justify-between'>
                <Pagination
                  count={data?.count}
                  limit={limit}
                  setLimit={setLimit}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
                <button
                  onClick={downloadTransactionsCSV}
                  disabled={isLoading || Boolean(!data?.results.length)}
                  type="button"
                  className="
                    rounded-md 
                    bg-blue-500 p-2 text-xs font-semibold text-white shadow-sm 
                    hover:bg-blue-400 
                    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 
                    disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Download csv
                </button>

              </Toolbar>
              {loading ? (
                <Loader />
              ) : (
                <List
                  loading={isLoading}
                  loadingMessage="Crunching data..."
                  data={data?.results || []}
                  className="w-full h-[40vh] min-h-[40vh]"
                  spanClass="text-white text-xs"
                  selected={() => false}
                  // onClick={(e: any, tx: Transaction) => {
                    // console.log(`Clicked transaction id=${tx.id}`)
                    // e.nativeEvent.preventDefault()
                    // console.log(e)
                    // navigate(`/revenue/${tx.id}`);
                  // }}
                  schema={[
                    {
                      label: "",
                      key: (tx: Transaction) => {
                        return (
                          <div className="flex items-center justify-start gap-1">
                            <span className="text-zinc-600 dark:text-white text-xs">
                              {listUTCDateFormat(new UTCDate(tx.start_date))} -
                              {listUTCDateFormat(new UTCDate(tx.end_date))}
                            </span>
                          </div>
                        );
                      },
                      type: "custom",
                    },
                    {
                      label: "Date",
                      type: "custom",
                      key: (tx: Transaction) => (
                        <span className="text-zinc-600 dark:text-white text-xs font-mono">
                          {listUTCDateFormat(new UTCDate(tx.created_at))}
                        </span>
                      ),
                    },
                    {
                      label: "Amount",
                      type: "number",
                      key: (tx: Transaction) => (
                        <span className="text-zinc-600 dark:text-white text-xs font-mono">
                          {(tx.amount_sats / 10 ** 8).toFixed(8)} BTC
                        </span>
                      ),
                    },
                    {
                      label: "TXID",
                      type: "custom",
                      key: (tx: Transaction) => {
                        return (<>
                          {(tx.txid) ? (<>
                            <a
                              className="text-zinc-600 dark:text-slate-300 text-xs text-ellipsis overflow-hidden block max-w-28" 
                              href={`https://mempool.space/tx/${tx.txid}`}
                              target="_blank"
                              onClick={() => {
                                window.open(`https://mempool.space/tx/${tx.txid}`, '_blank')
                              }}
                            >
                              {tx.txid}
                            </a>
                          </>) : (<>
                            <span className="text-zinc-600 dark:text-white text-xs max-w-32"> - </span>
                          </>)
                          }
                        </>);
                      },
                    },
                    {
                      label: "Address",
                      type: "custom",
                      key: (tx: Transaction) => {
                        return (
                          <span className="text-zinc-600 dark:text-white text-xs select-all">
                            {tx.address ?? "-"}
                          </span>
                        );
                      },
                    },
                    {
                      label: "Download receipt",
                      type: "custom",
                      key: (tx: Transaction) => {
                        return (<>
                          <span className="text-zinc-600 dark:text-white text-xs select-all me-1">{tx.filename}</span>
                          <button
                            onClick={() => {
                              downloadReceiptText(tx)
                            }}
                            disabled={isLoading || Boolean(!data?.results.length)}
                            type="button"
                            className="
                              rounded-md bg-blue-500 
                              px-2 py-1 me-1
                              text-xs font-semibold text-white shadow-sm 
                              hover:bg-blue-400 
                              focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 
                              disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            .txt
                          </button>
                          <button
                            onClick={() => {
                              downloadReceiptPDF(tx)
                            }}
                            disabled={isLoading || Boolean(!data?.results.length)}
                            type="button"
                            className="
                              rounded-md bg-blue-500 
                              px-2 py-1 me-1
                              text-xs font-semibold text-white shadow-sm 
                              hover:bg-blue-400 
                              focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 
                              disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                            .pdf
                          </button>
                        </>);
                      },
                    },
                  ]}
                />
              )}
            </Card>
          </div>
        </View>
      </div>
    </div>
  );
}
