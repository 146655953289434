import { get, url } from '@rigly/core';
import toast from 'react-hot-toast';
import { getToken } from '../utils/token';
import { getDefaultHeaders } from '../utils/headers';
import { BASE_API_URL } from '../constants';

export async function getTransactions({
  teamId,
  limit,
  offset,
}: {
  teamId: number;
  limit: number;
  offset: number;
}) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('Invalid token');
    }

    const queryUrl = `/api/sellers/transactions?team=${teamId}&limit=${limit}&offset=${offset}`;

    const res = await get(
      url(BASE_API_URL, queryUrl), 
      {
        headers: getDefaultHeaders(token),
      },
    )

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: 'bottom-right' });
  }
}


export async function getTransactionsCSV({
  teamId,
  limit,
  offset,
}: {
  teamId: number;
  limit: number;
  offset: number;
}) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('Invalid token');
    }

    const queryUrl = `/api/sellers/transactions/csv?team=${teamId}&limit=${limit}&offset=${offset}`;

    const res = await fetch(
      url(BASE_API_URL, queryUrl), 
      {
        headers: getDefaultHeaders(token),
      },
    )

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: 'bottom-right' });
  }
}


export async function getTransactionReceipt(
  transaction_id: number,
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/transactions/receipt`;

    const queryParams = []
    queryParams.push(`transaction_id=${transaction_id}`);

    if (queryParams.length > 0) {
      queryUrl = [queryUrl, queryParams.join('&')].join('?')
    }

    const res = await fetch(
      url(BASE_API_URL, queryUrl),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'text/html',
        },
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}


export async function getTransactionReceiptPDF(
  transaction_id: number,
) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    let queryUrl = `/api/sellers/transactions/receipt-pdf`;

    const queryParams = []
    queryParams.push(`transaction_id=${transaction_id}`);

    if (queryParams.length > 0) {
      queryUrl = [queryUrl, queryParams.join('&')].join('?')
    }

    const res = await fetch(
      url(BASE_API_URL, queryUrl),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'text/html',
        },
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}
