import { Card, Icon } from "@rigly/core";
import clsx from "clsx";

export default function StatisticCard({
  className,
  title,
  value,
  description,
  icon,
  loading,
  loadingPlaceholder,
  suffix,
}: {
  className: string;
  title: string;
  value: any;
  description: string;
  icon: string;
  loading?: boolean;
  loadingPlaceholder?: string;
  suffix?: string;
}) {
  return (
    <Card
      className={clsx(
        "p-8 bg-radial-gradient-dark dark:bg-radial-gradient-light",
        className
      )}
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-start">
          <span className="text-zinc-500 dark:text-white text-sm mb-2">
            {title}
          </span>
          <Icon icon={icon as any} className="w-4 h-4 text-white/50" />
        </div>
        <span className="text-zinc-500 dark:text-white text-3xl font-bold mb-1">
          {loading ? `${loadingPlaceholder} ${suffix}` : `${value} ${suffix}`}
        </span>
        <span className="text-zinc-500 dark:text-white/50 text-xs">
          {description}
        </span>
      </div>
    </Card>
  );
}
